@use "@jeffcarbine/premmio/public/components/alert/alert";
@use "@jeffcarbine/premmio/public/components/loader/loader";
@use "@jeffcarbine/premmio/public/components/modal/modal";
@use "@jeffcarbine/premmio/public/components/cookieConsent/cookieConsent";
@use "@jeffcarbine/premmio/public/components/card/card";

@use "./partials/product";

:root {
  --loader-color: var(--accent-500);
}
